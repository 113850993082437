import WebCoding from '../images/main-images/WebCoding.svg'
import MobileCoding from '../images/main-images/MobileCoding.svg'
import ProductDesign from '../images/main-images/ProductDesign.svg'
import { ProjectsData } from './ProjectsData'

import contacts from '../images/icons/contacts.png';
import engagement from '../images/icons/engagement.png';
import latestTechnologies from '../images/icons/latest-technologies.png';
import experience from '../images/icons/experience.png';
import testing from '../images/icons/testing.png';
import quality from '../images/icons/quality.png';

import react from "../images/technologies/react.svg"
import typeScript from "../images/technologies/typescript.svg"
import javaScript from "../images/technologies/javascript.svg"
import mongoDb from "../images/technologies/mongodb.svg"
import cassandra from "../images/technologies/cassandra.svg"
import cpp from "../images/technologies/cpp.svg"
import tsql from "../images/technologies/tsql.svg"
import mysql from "../images/technologies/mysql.svg"
import net from "../images/technologies/net.svg"
import csharp from "../images/technologies/csharp.svg"
import android from "../images/technologies/android.svg"
import kotlin from "../images/technologies/kotlin.svg"
import spring from "../images/technologies/spring.svg"
import java from "../images/technologies/java.svg"

const Advantages = [
    [
        {
            id: 1,
            image: latestTechnologies,
            header:"Najnowsze technologie",
            desc:"Realizując aplikacje wykorzystujemy najnowsze trendy technologiczne dobierając je odpowiednio do postawionych wymagań projektowych.",
        },
        {
            id: 2,
            image: experience,
            header:"Doświadczenie",
            desc:"Nasz zespół posiada wieloletnie doświadczenie, dzięki któremu wasze projekty są sprawnie i szybko realizowane.",
        },
        {
            id: 3,
            image: engagement,
            header:"Zaangażowanie",
            desc:"Tworzenie oprogramowania jest naszą pasją, gwarantujemy, że do każdego projektu podchodzimy z najwyższym zaangażowaniem.",
        },
    ],
    [
        {
            id: 4,
            image: testing,
            header:"Testowanie i optymalizację",
            desc:"Kładziemy duży nacisk na testowanie funkcjonalne i wydajnościowe, oraz optymalizację kodu i wydajności aplikacji, zapewniając stabilność i efektywność naszych produktów."
        },
        {
            id: 5,
            image: contacts,
            header:"Stały kontakt",
            desc:"Zdajemy sobie sprawę, że podczas trwania procesu realizowania oprogramowania stały kontakt z klientem jest na wagę złota. W trakcie współpracy dbamy o to wykorzystując do tego różne formy oraz narzędzia.",
        },
        {
            id: 6,
            image: quality,
            header:"Jakość",
            desc:"Wytwarzając oprogramowanie trzymamy się standardów programowania, wykorzystujemy wzorce projektowe oraz podchodzimy do każdego projektu z najwyższą starannością i uwagą co przekłada się na wysoką jakość realizowanych projektów.",
        }
    ]
];

export const ServiceData = {
    ParamasUrl:[
        'aplikacje-webowe',
        'aplikacje-mobilne',
        'produkt-design'
    ],
    Services:[
        {
            Main:{
                header:'Aplikacje webowe',
                desc:'Tworzymy dostosowane do Twoich potrzeb dedykowane aplikacje internetowe spełniające najwyższe standardy. Przekształcimy Twój pomysł, w prawdziwy produkt.',
                img: WebCoding,
                backgroundNumber: '01',
                dataLink:{
                    link: '/usługi/aplikacje-mobilne',
                    linkText: 'Następne'
                }
            },
            Title:{
                header: "Zalety aplikacji webowych",
                desc: "Sprawdź, dlaczego warto zdecydować się na aplikację internetową."
            },
            Description:{
                Left:{
                    header: "Dlaczego warto?",
                    desc: "Aplikacjami internetowymi nazywamy serwisy internetowe umożliwiające na korzystanie z Twojego programu użytkownikom bez wymagania instalacji Twojego oprogramowania na ich urządzeniach. Dużym atutem tego rozwiązania jest łatwy dostęp do Twojego produktu, wymagający jedynie połączenia z internetem. Kolejnymi rzeczami, które przekonywują na zdecydowanie się na ten typ usługi jest:",
                },
                Right:[
                    {
                        id: '1',
                        title: 'Dostępność i łatwość użytkowania',
                        desc: 'Aplikacje internetowe są dostępne poprzez przeglądarkę internetową na różnych urządzeniach, co oznacza, że użytkownicy mogą korzystać z nich bez konieczności instalowania dodatkowego oprogramowania. To sprawia, że są one łatwo dostępne dla szerokiego grona osób, co przekłada się na większą dostępność i wygodę użytkowania.'
                    },
                    {
                        id: '2',
                        title: 'Uniwersalność i skalowalność',
                        desc: 'Aplikacje internetowe działają na różnych systemach operacyjnych i urządzeniach, co sprawia, że są uniwersalne i łatwe do skalowania. Niezależnie od tego, czy użytkownik korzysta z komputera stacjonarnego, laptopa, smartfona czy tabletu, może mieć dostęp do aplikacji internetowej. Ponadto, skalowanie aplikacji internetowych jest stosunkowo proste, co pozwala na elastyczne dostosowanie się do zmieniających się potrzeb i liczby użytkowników.'
                    },
                    {
                        id: '3',
                        title: 'Łatwa aktualizacja i zarządzanie',
                        desc: 'Aktualizacje aplikacji internetowych mogą być łatwo wdrażane na serwerze, co oznacza, że użytkownicy nie muszą pobierać i instalować żadnych aktualizacji lokalnie. To zapewnia spójność i szybkie dostarczanie nowych funkcji oraz poprawek bez konieczności przerywania pracy użytkowników. Ponadto, zarządzanie użytkownikami, uprawnieniami i danymi może być łatwiejsze dzięki centralnemu zarządzaniu, co z kolei wpływa na wydajność i bezpieczeństwo aplikacji.'
                    },
                ]
            },
            AdvantagesTitle:{
                header: "Dlaczego nasze usługi to dobry wybór?",
            },
            Advantages: Advantages,
            OurTechnologiesTitle:{
                header: "Technologie których używamy"
            },
            Technologies:{
                images:[
                    react,
                    typeScript,
                    javaScript,
                    mongoDb,
                    cassandra,
                    cpp,
                    tsql,
                    mysql,
                    net,
                    csharp,
                    kotlin,
                    spring,
                    java
                ]
            },
            ProjectsTitle:{
                header: "Zrealizowane przez nas projekty"
            },
            Project: ProjectsData.Projects
        },
        {
            Main:{
                header:'Aplikacje mobilne',
                desc:'Zajmujemy się całym procesem kreowania Twojej aplikacji mobilnej, od przygotowania makiet i prototypów aż po wdrożenie Twojego produktu. Z nami możesz liczyć na realizację spełniającą Twoje oczekiwania.',
                img: MobileCoding,
                backgroundNumber: '02',
                dataLink:{
                    link: '/usługi/produkt-design',
                    linkText: 'Następne'
                }
            },
            Title:{
                header: "Zalety aplikacji mobilnych",
                desc: "Sprawdź dlaczego warto zdecydować się na aplikację mobilną."
            },
            Description:{
                Left:{
                    header: "Dlaczego warto?",
                    desc: "W dzisiejszych czasach smartfony stały się nieodłącznym elementem życia codziennego. Posiadanie własnej aplikacji mobilnej daje Twojemu produktowi przewagę nad konkurencją, która jeszcze jej nie posiada. Technologie mobilne oferują szereg możliwości interakcji z użytkownikami oraz dostarczają cenne dane analityczne. Oto kilka zalet, jakie niesie za sobą posiadanie aplikacji mobilnej:",
                },
                Right:[
                    {
                        id: '1',
                        title: 'Zaawansowane funkcje dla lepszej satysfakcji użytkowników:',
                        desc: 'Aplikacje mobilne umożliwiają implementację zaawansowanych funkcji, które zwiększają satysfakcję użytkowników i poprawiają interakcję z Twoim produktem. Wykorzystanie powiadomień może dodatkowo zachęcić klientów do powrotu do aplikacji.'
                    },
                    {
                        id: '2',
                        title: 'Oszczędność czasu dla użytkowników:',
                        desc: 'Dzięki aplikacji mobilnej użytkownicy mają łatwy dostęp do Twojego produktu bezpośrednio z ich urządzeń mobilnych. Wystarczy jedynie zainstalować Twoją aplikację na ich telefonach, co przyspiesza i ułatwia korzystanie z Twojej usługi.'
                    },
                    {
                        id: '3',
                        title: 'Dostępność offline dla ciągłej pracy:',
                        desc: ' Jedną z głównych zalet aplikacji mobilnych jest możliwość pracy w trybie offline lub przy słabym połączeniu internetowym. To oznacza, że użytkownicy mogą korzystać z aplikacji bez względu na dostępność internetu, co zwiększa użyteczność i dostępność Twojego produktu.'
                    },
                ]
            },
            AdvantagesTitle:{
                header: "Dlaczego nasze usługi to dobry wybór?",
            },
            Advantages: Advantages,
            OurTechnologiesTitle:{
                header: "Technologie których używamy"
            },
            Technologies:{
                images:[
                    android,
                    kotlin,
                    java,
                    react,
                    typeScript,
                    javaScript
                ]
            },
            ProjectsTitle:{
                header: "Zrealizowane przez nas projekty"
            },
            Project: ProjectsData.Projects
        },
        {
            Main:{
                header:'Produkt design',
                desc:'Oferujemy wsparcie jak i zadbanie o cały proces projektowania Twojego produktu. Badamy potrzeby użytkowników i dbamy o to by efekt końcowy wizualnie zachwycał nawet najbardziej wymagające oko.',
                img: ProductDesign,
                backgroundNumber: '03',
                dataLink:{
                    link: '/usługi/aplikacje-webowe',
                    linkText: 'Następne'
                }
            },
            Title:{
                header: "Zalety produkt design",
                desc: "Odkryj, dlaczego warto skorzystać z naszych usług w zakresie projektowania produktów."
            },
            Description:{
                Left:{
                    header: "Dlaczego warto?",
                    desc: "Podążanie za celami i założeniami projektu jest kluczowe podczas procesu tworzenia aplikacji. Dzięki zastosowaniu odpowiednich technik weryfikacji oczekiwań użytkowników jesteśmy w stanie dostarczyć Ci produkt, który spełnia najwyższe standardy i idealnie pasuje do potrzeb przyszłych użytkowników. Korzyściami wynikającymi z wykorzystania usług projektowania zorientowanego na użytkowników są:",
                },
                Right:[
                    {
                        id: '1',
                        title: 'Minimalizacja kosztów',
                        desc: 'Poprawnie zaprojektowana aplikacja, oparta na dokładnej analizie celów i wymagań projektowych, znacząco obniża koszty produkcji oprogramowania.'
                    },
                    {
                        id: '2',
                        title: 'Zwiększenie zadowolenia klientów',
                        desc: 'Stawiamy na tworzenie produktów dopasowanych do potrzeb i oczekiwań użytkowników, co przekłada się na ostateczną ocenę i satysfakcję z korzystania z Twojej aplikacji.'
                    },
                    {
                        id: '3',
                        title: 'Poprawa wydajności',
                        desc: 'Znając dokładny cel oraz posiadając określone wymagania i funkcjonalności Twojego produktu, jesteśmy w stanie skupić się na poprawie wydajności i sprawieniu, że Twoja aplikacja będzie działała intuicyjnie oraz szybciej.'
                    },
                ]
            },
            AdvantagesTitle:{
                header: "Dlaczego nasze usługi to dobry wybór?",
            },
            Advantages: Advantages,
            ProjectsTitle:{
                header: "Zrealizowane przez nas projekty"
            },
            Project: ProjectsData.Projects
        }
    ]
}