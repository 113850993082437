import React , { useState , useEffect }from 'react'
import LogoImg from '../images/necodem.png'
import styled from 'styled-components'
import Image from 'react-bootstrap/Image'
import { Link, NavLink } from 'react-router-dom'
import { device } from '../device'
import { Spin as Hamburger } from 'hamburger-react'
import Container from 'react-bootstrap/Container'

const ShadowBlock = styled.div`
    width: 100%; 
    height: 100%; 
    background-color: rgba(0, 0, 0, 0.4); 
    position: ${props => (props.isOpen ? 'fixed' : 'inherit')};
    z-index: 1;
    opacity: ${props => (props.isOpen ? '1' : '0')};
    transition:  0.5s;
`;
const NavigationContainer = styled.div`
    display: flex;
`;
const Navigation = styled.div`
    margin-top: 25px;
    height: 58px;
    display: flex;
    justify-content: space-between;
    width: 100%; 
`;
const Logo = styled(Link)`
    height: 57px;
    width: 166px;
`;
const Menu = styled.ul`
    margin-top: 15px;
    width: 400px;
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    align-items: center;
    @media ${device.tablet}{
        align-items: baseline;
        display: none;
    }
`;
const MenuItem = styled.li`
    text-decoration: none;
`;
const MenuStyledLink = styled(NavLink)`
    text-decoration: none !important;
    font-weight: 500;
    color: black !important;
    &:hover {
        color: ${props => props.theme.colors.darkBlue};
        transition:  0.3s;
    }
    &.active {
        color: ${props => props.theme.colors.darkBlue};
    }
    @media ${device.tablet}{
        display: none;
    }
`;
const HamburgerItem = styled.div`
    display: none;
    @media ${device.tablet}{
        display: block;
        position: absolute;
        right: 14px;
        top: 34px;
        z-index: 999;
    }
`;
const SideBar = styled.div`
    display: none;
    @media ${device.tablet}{
        width: ${props => (props.isOpen ? '280px;' : '0px')};
        display: block;
        transition:  width 0.5s;
        height: 100vh;
        background-color: #FFFFFF;
        position: fixed;
        z-index: 2;
        right: 0;
        top:0;
    }
    @media ${device.mobileL}{
        width: ${props => (props.isOpen ? '100%;' : '0px')};
    }
`;
const SideBarMenu = styled.ul`
    list-style-type: none;
    display: none;
    @media ${device.tablet}{
        display: ${props => (props.isOpen ? 'flex' : 'none')};  
        flex-direction: column;
        position: relative;
        top: 85px;
        left: 21px;
    }
`;
const SideBarMenuItem = styled.li`
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid #CECECE;
    width: 77%;
`;
const SideBarStyledLink = styled(NavLink)`
    text-decoration: none !important;
    font-weight: 500;
    color: black !important;
    &:hover {
        color: ${props => props.theme.colors.darkBlue};
        transition:  0.3s;
    }
    &.active {
        color: ${props => props.theme.colors.darkBlue};
    }
`;

const NavigationBar = () => {

    const [ openMenu, setOpenMenu ] = useState(false)

    const disableScroll = () => { document.body.style.overflow = 'hidden' }
    const enabledScroll = () => { document.body.style.overflow = 'auto' }

    const handleClick = () => {
        setOpenMenu(!openMenu)
        openMenu ?  enabledScroll() : disableScroll()
    }

    useEffect(() => {

        function handleResize(){

            if(window.innerWidth >= 780){
                setOpenMenu(false)
                enabledScroll()
            }

        }

        window.addEventListener('resize' , handleResize)
    })
    
    return (
        <>
        <ShadowBlock isOpen={openMenu} onClick={handleClick}/>
        <Container fluid="xl">
            <NavigationContainer>
                <Navigation>
                    <Logo to='/'>
                        <Image src={LogoImg} fluid />
                    </Logo>
                    <Menu>
                        <MenuItem>
                            <MenuStyledLink to='/usługi'>Usługi</MenuStyledLink>
                        </MenuItem>
                        <MenuItem>
                            <MenuStyledLink to='/realizacje'>Realizacje</MenuStyledLink>
                        </MenuItem>
                        <MenuItem>
                            <MenuStyledLink to='/kontakt'>Kontakt</MenuStyledLink>
                        </MenuItem>
                    </Menu>
                </Navigation>
            </NavigationContainer>
        </Container>
        <HamburgerItem onClick={handleClick} >
            <Hamburger size={22} toggled={openMenu}/>
        </HamburgerItem>
        <SideBar isOpen={openMenu}>
            <SideBarMenu isOpen={openMenu}>
                <SideBarMenuItem>
                        <SideBarStyledLink to='/usługi' onClick={handleClick} >Usługi</SideBarStyledLink>
                </SideBarMenuItem>
                <SideBarMenuItem>
                        <SideBarStyledLink to='/realizacje' onClick={handleClick}>Realizacje</SideBarStyledLink>
                </SideBarMenuItem>
                <SideBarMenuItem>
                        <SideBarStyledLink to='/kontakt' onClick={handleClick}>Kontakt</SideBarStyledLink>
                </SideBarMenuItem>
            </SideBarMenu>
        </SideBar>     
        </>
    )
}

export default NavigationBar
