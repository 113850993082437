import projectsImage from '../images/main-images/Projects.svg';

import icoAllColor from '../images/icons/all-color.png';
import icoAllWhite from '../images/icons/all-white.png';

import icoMobileColor from '../images/icons/mobile-color.png';
import icoMobileWhite from '../images/icons/mobile-white.png';

import iconWebColor from '../images/icons/web-color.png';
import iconWebWhite from '../images/icons/web-white.png';

import icoDesignColor from '../images/icons/design-color.png';
import icoDesignWhite from '../images/icons/design-white.png';

import { ProjectsData } from './ProjectsData';

export const ProjectsListData = {
    Main:{
        header:'Nasze wybrane realizacje',
        desc:'Projektowanie systemów informatycznych jest zarówno nasza pasja jak i tym w czym czujemy się najlepiej. Sprawdź nasze rozwiązania.',
        img: projectsImage,
        reversed: 'false',
        button:{
            link:"/kontakt",
            text:"Skontaktuj się z nami",
        }
    },
    ProjectsListTitle:{
        header: "Zapoznaj się z szczegółami projektów"
    },
    ProjectsDetails:{
        buttons:[
            {
                id: 0,
                text: 'Wszystkie aplikacje',
                imageColorSrc: icoAllColor,
                imageWhiteSrc: icoAllWhite   
            },
            {
                id: 1,
                text: 'Aplikacje mobilne',
                imageColorSrc: icoMobileColor,
                imageWhiteSrc: icoMobileWhite
            },
            {
                id: 2,
                text: 'Aplikacje webowe',
                imageColorSrc: iconWebColor,
                imageWhiteSrc: iconWebWhite 
            },
            {
                id: 3,
                text: 'Produkt design',
                imageColorSrc: icoDesignColor,
                imageWhiteSrc: icoDesignWhite 
            }
        ],
        isInteractive: 'false',
        projectsArray: ProjectsData.Projects.projectsArray
    }
}