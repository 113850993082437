import mainImage from '../images/main-images/404.svg'

export const NotFoundData = {
    Main:{
        header:'Strona nie została znaleziona',
        desc:'Wygląda na to, że zabłądziłeś, czy chcesz powrócić do strony głównej?',
        img: mainImage,
        reversed: 'false',
        button:{
            link:"/",
            text:"Powrót do strony głównej",
        }
    }
}