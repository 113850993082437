import React , { useState, useRef } from 'react'
import PointLine from '../elements/PointLine'
import styled from 'styled-components'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import IconColumn from '../elements/IconColumn'
import Paragraph from '../elements/Paragraph'
import AppearDiv from '../elements/AppearDiv'

const PointLineContainer = styled.div`
    display: flex;
    justify-content: space-between;
    transition: all 2.00s;
`;
const Line = styled.div`
    border-bottom: 2px solid #323333;
    position: relative;
    top:41px;
    z-index: -15;
    width: 80%;
    left: 70px;
    transition: all 2.00s;
    @media (max-width: 575px) {
        display: none;
    }
`;
const CustomRow = styled(Row)`
    margin-top: 70px;
    opacity: ${props => props.opacity};
    transition: all 0.20s ease-out;
`;

const PointLineContainerSecond = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 2.00s;
`;

const HowWeWorks = (props) => {
    const [numberOfParagraphs , setNumberOfParagraphs] = useState(0);
    const selectedParagraph = useRef(0);
    const [opacity, setOpacity] = useState(1);

    function handleClick(event){
        const targetId = event.target.dataset.id - 1;
        if (isNaN(targetId) || targetId === selectedParagraph.current) return;
        setOpacity(0)
        setTimeout(() => {
            selectedParagraph.current = parseInt(targetId)
            setNumberOfParagraphs(selectedParagraph.current)
        }, 200);
        setTimeout(() => { setOpacity(1) }, 400);
    }

    let iconColumnList = props.data.map((item) =>
    <PointLineContainerSecond key={item.id} id={item.id}>
            <PointLine 
                data={item} 
                key={item.id} 
                data-id={item} 
                active={numberOfParagraphs+1}/>
    </PointLineContainerSecond> 
    )
    
    return (
        <AppearDiv>
            <Line />
            <PointLineContainer onClick={handleClick}>
                    {iconColumnList}
            </PointLineContainer>
            
            <CustomRow opacity={opacity}>
                <Col md={6}>
                    <IconColumn data={props.data[numberOfParagraphs]}/>    
                </Col>
                <Col md={6}>
                    {
                        props.data[numberOfParagraphs].paragraphsArray.map((item) =>
                            <Paragraph key={item.id} data={props.data[numberOfParagraphs].paragraphsArray[item.id-1]}/>
                        )
                    }
                </Col>
            </CustomRow>
        </AppearDiv>
    )
}

export default HowWeWorks
