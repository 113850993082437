import React from 'react'
import Container from 'react-bootstrap/Container'
import AppearDiv from '../elements/AppearDiv'
import TermParagraph from '../elements/TermParagraph'

const Terms = (props) => {

    const termsParagraph = props.data.paragraphs.map((item) => 
        <TermParagraph data={item} key={item.header}/>
    )

    return (
        <AppearDiv>
            <Container>
                <p>{props.data.mainDesc}</p>
                {termsParagraph}
            </Container>
        </AppearDiv>
    )
}

export default Terms
