import React, { useState } from 'react'
import styled from 'styled-components'
import CustomLink from '../elements/CustomLink'
import useStateRef from 'react-usestateref'
import AppearDiv from '../elements/AppearDiv'
import { device } from '../device'

const ProjectContainer = styled.div`
    border-radius: 10px;
    background-image: url(${props => props.background});
    width: 100%;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    opacity: ${props => (props.opacity ? '1;' : '0')};
    transition: opacity 0.6s ease;
    align-items: center;

    div{
        opacity: 1;
        transition: all 1.5s ease;
    }

    @media ${device.tablet}{
        height: 350px;
    }

    @media (min-width: 769px) {
        height: 500px;
    }
`

const ProjectDescriptionContainer = styled.div`
    margin-right: 8px;

    @media ${device.mobileL}{
        flex: 0.5;
    }

    @media (min-width: 426px){
        flex: 0.6;
    }
`;

const ProjectHeader = styled.h3`
    color:#FFFFFF !important;
    word-wrap: break-word;
    margin-bottom: 20px;

    @media ${device.mobileL}{
        font-size: 18px;
    }

    @media (min-width: 426px){
        font-size: 20px;
    }
`;

const NextProjectContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
`;

const Project = (props) => {
    const [numberOfProject, setNumberOfProject, numberOfProjectRef] = useStateRef(0)
    const [opacity, setOpacity] = useState(1)
    const [lastClicked, setLastClicked] = useState(0)
    const [ projectsCount ] = useState(props.data?.projectsArray?.length ?? 1);

    const dataLink = {
        link: '#',
        linkText: 'Następny projekt'
    }

    function nextProject() {
        if (projectsCount === 1) return;
        if (Date.now() - lastClicked < 1000) return;
        setLastClicked(Date.now())
        let projectsArrayLenght = projectsCount - 1
        if (numberOfProjectRef.current >= projectsArrayLenght) {
            setOpacity(0)
            setTimeout(() => {
                setNumberOfProject(0);
                setOpacity(1);
            }, 500);
        } else {
            setOpacity(0)
            setTimeout(() => {
                setNumberOfProject(numberOfProjectRef.current + 1)
                setOpacity(1)
            }, 500);
        }
    }

    return (
        <AppearDiv>
            {!props.data.isInteractive &&
                <ProjectContainer background={props.data.image} opacity={opacity}>
                    <div style={{flex: 1}}/>
                    <ProjectDescriptionContainer>
                        <ProjectHeader>{props.data.header}</ProjectHeader>
                        <CustomLink data={props.data} color={'#FFFFFF !important;'} isIcon={true} />
                    </ProjectDescriptionContainer>
                </ProjectContainer>
            }
            {props.data.isInteractive &&
                <>
                    <ProjectContainer background={props.data.projectsArray[numberOfProject].image} opacity={opacity}>
                        <div style={{flex: 1}}/>
                        <ProjectDescriptionContainer>
                            <ProjectHeader>{props.data.projectsArray[numberOfProject].header}</ProjectHeader>
                            <CustomLink 
                                data={props.data.projectsArray[numberOfProject]} 
                                color={'#FFFFFF !important;'} 
                                isIcon
                                bold={'false'}/>
                        </ProjectDescriptionContainer>

                    </ProjectContainer>

                    {
                        projectsCount > 1 &&
                        <NextProjectContainer onClick={nextProject}>
                            <CustomLink 
                                data={dataLink} 
                                color={'black !important;'} 
                                isIcon
                                bold={'true'}
                            />
                        </NextProjectContainer>
                    }

                </>
            }
        </AppearDiv>
    )
}

export default Project
