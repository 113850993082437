import React from 'react'
import ContactMain from '../components/ContactMain'
import PageContainer from './PageContainer'

const Contact = () => {
    return (
        <PageContainer>
            <ContactMain/>
        </PageContainer>
    )
}

export default Contact
