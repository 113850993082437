import React , { useState ,useEffect, useCallback } from 'react'
import Main from '../components/Main'
import Title from '../components/Title'
import { ServiceData } from '../data/ServiceData'
import Description from '../components/Description'
import ServicesAdvantages from '../components/ServicesAdvantages'
import OurTechnologies from '../components/OurTechnologies'
import Project from '../components/Project'
import { useParams , useHistory  } from "react-router-dom"
import styled from 'styled-components'
import PageContainer from './PageContainer'

const MainDiv = styled.div`
  transition: opacity 1.00s;
  opacity: ${props => props.opacity};
`

const Service = () => {
    const { id } = useParams();
    const history = useHistory();
    const [ selectedServiceId , setSelectedServiceId ] = useState(0);
    const [ opacity, setOpacity ] = useState(0);

    const getServiceById = useCallback(() => {
        setOpacity(1);
        let index = ServiceData.ParamasUrl.indexOf(id)
        if (index === -1)
            history.push("/");
        if(selectedServiceId !== index){
            setOpacity(0)
            setTimeout(() => {
                setSelectedServiceId(index)
                setOpacity(1)
            }, 1000);
        }
    },[history, id, selectedServiceId])

    useEffect(() => {
        getServiceById();
    }, [id, getServiceById])


    return (
        <PageContainer>
            <MainDiv opacity={opacity}>
                <Main data={ServiceData.Services[selectedServiceId].Main}/>
                <Title data={ServiceData.Services[selectedServiceId].Title}/>
                <Description data={ServiceData.Services[selectedServiceId].Description}/>
                <Title data={ServiceData.Services[selectedServiceId].AdvantagesTitle}/>
                <ServicesAdvantages data={ServiceData.Services[selectedServiceId].Advantages}/>
                
                { ServiceData.Services[selectedServiceId].Technologies &&
                  ServiceData.Services[selectedServiceId].OurTechnologiesTitle && (
                    <>
                    <Title data={ServiceData.Services[selectedServiceId].OurTechnologiesTitle}/>
                    <OurTechnologies data={ServiceData.Services[selectedServiceId].Technologies}/>
                    <br />
                    </>
                  )
                }
                
                <Title data={ServiceData.Services[selectedServiceId].ProjectsTitle}/>
                <Project data={ServiceData.Services[selectedServiceId].Project}/>
            </MainDiv>
        </PageContainer>
    )
}

export default Service
