import mainImage from '../images/main-images/MainPage.svg'
import iconWeb from '../images/icons/web-color.png'
import icoMobile from '../images/icons/mobile-color.png'
import icoDesign from '../images/icons/design-color.png'
import icoAnalist from '../images/icons/analist.svg'
import icoImplementationAndMaintenance from '../images/icons/review.svg'
import icoWorkspace from '../images/icons/workspace.svg'

//technologies
import react from "../images/technologies/react.svg"
import typeScript from "../images/technologies/typescript.svg"
import javaScript from "../images/technologies/javascript.svg"
import mongoDb from "../images/technologies/mongodb.svg"
import cassandra from "../images/technologies/cassandra.svg"
import cpp from "../images/technologies/cpp.svg"
import tsql from "../images/technologies/tsql.svg"
import mysql from "../images/technologies/mysql.svg"
import net from "../images/technologies/net.svg"
import csharp from "../images/technologies/csharp.svg"
import android from "../images/technologies/android.svg"
import kotlin from "../images/technologies/kotlin.svg"
import spring from "../images/technologies/spring.svg"
import java from "../images/technologies/java.svg"

import { ProjectsData } from './ProjectsData'

export const MainPageData = {
    Main:{
        header:'Necodem Software house',
        desc:'Tworzymy zaawansowane oprogramowania najwyższej jakości, nasz doświadczony zespół sprawi że Twój pomysł stanie się rzeczywistością. Do każdego projektu podchodzimy z pasją i pełnym profesjonalizmem.',
        img: mainImage,
        reversed: 'false',
        button:{
            link:"/kontakt",
            text:"Skontaktuj się z nami",
        }
    },
    ServicesTitle:{
        header: "Nasze usługi",
        desc: "Zapoznaj się z tym, w czym jesteśmy najlepsi"
    },
    Services:[
        {
            id: 0,
            image: iconWeb,
            header:"Aplikacje webowe",
            desc:"Zajmujemy się tworzeniem dedykowanych aplikacji internetowych pozwalających skalować i rozwijać Twój biznes. Zapewniamy aplikacje dostosowane do specyfikacji projektu oraz Twoich wymagań.",
            link:"/usługi/aplikacje-webowe",
            linkText:"Więcej informacji"
        },
        {
            id: 1,
            image: icoMobile,
            header:"Aplikacje mobilne",
            desc:"Tworzymy zaawansowane i spełniające najwyższe standardy aplikacje mobilne. Gwarantujemy zrealizowanie całego procesu tworzenia oprogramowania od zrealizowania projektu po wdrożenie go u klienta.",
            link:"/usługi/aplikacje-mobilne",
            linkText:"Więcej informacji"
        },
        {
            id: 2,
            image: icoDesign,
            header:"Produkt design",
            desc:"Dostarczamy doświadczenie oraz wsparcie niezbędne do przekształcenia Twojego pomysłu w realny produkt. Projektujemy działanie i wygląd interfejsów użytkownika według panujących trendów i dobrych wzorców.",
            link:"/usługi/produkt-design",
            linkText:"Więcej informacji"
        }
    ],
    HowWeWorksTitle:{
        header: "Sposób naszej pracy",
        desc: "Produkcja oprogramowania to złożony proces składający się z wielu elementów, sprawdź jak my to robimy."
    },
    HowWeWorksPoints:[
        {
            id:1,
            title: "Analiza potrzeb klienta",
            image: icoAnalist,
            header: "Analiza potrzeb klienta",
            desc: "Jednym z pierwszych kroków jest dogłębna analiza projektu i poznanie jego założeń. Dzięki dostarczonym informacji oraz rozmów z klientem weryfikujemy cele, zakres pracy i funkcjonalności, które mają się znajdować w aplikacji.",
            paragraphsArray:[
                {
                    id: 1,
                    title: "Brief",
                    desc: "Uzupełniasz przesłany przez nas dokument dzięki któremu ustalamy potrzebne dane na temat Twojej aplikacji. Na tym etapie weryfikujemy budżet przeznaczony na przygotowanie oprogramowania, wymagania i cele waszego projektu."
                },
                {
                    id: 2,
                    title: "Kontakt",
                    desc:  "Mając już określoną wizję i wasze oczekiwania kolejnym krokiem jest dokładne omówienie i ustalenie wszystkich potrzebnych szczegółów do dalszej współpracy. Po spotkaniu dostarczamy pełną wycenę i ramy czasowe potrzebne do wykonania zlecenia."
                }
            ]
        },
        {
            id:2,
            title: "Planowanie projektu",
            image: icoWorkspace,
            header: "Planowanie projektu",
            desc: "Kolejny krokiem jest wybranie metodologii pracy i w zależności od zastosowanego rodzaju przygotowujemy pełną specyfikację projektu lub opis wymagań potrzebnych do rozpoczęcia pracy w zwinnej metodologii zarządzaniem projektem zwanym SCRUM’em.",
            paragraphsArray:[
                {
                    id: 1 ,
                    title: "Określenie specyfikacji projektu",
                    desc:  "Wybieramy metodologię pracy, która najlepiej sprawdzi się podczas tworzenia Twojej aplikacji.Przygotowujemy dokument specyfikacji wymagań projektu, który jest niezbędny do odpowiedniego realizowania założeń aplikacji."
                },
                {
                    id: 2 ,
                    title: "Sporządzenie umowy",
                    desc:  "Jeśli nasza oferta spełnia Twoje oczekiwania możemy podpisać umowę i zacząć kreować Twój wymarzony produkt."
                },
            ]
        },
        {
            id:3,
            title: "Design Aplikacji",
            image: icoDesign,
            header: "Design Aplikacji",
            desc: "Kolejnym kluczowym etapem jest przygotowanie nie tylko atrakcyjnie wyglądającego interfejsu użytkownika, ale również spełniającego wszystkie oczekiwania co do jego użytkowania i funkcjonalności.",
            paragraphsArray:[
                {
                    id: 1,
                    title: "Klikalne makiety",
                    desc:  "Tworzymy interaktywne makiety aplikacji, które pozwalają na wczesne testowanie i ocenę interfejsu użytkownika przed rozpoczęciem implementacji."
                },
                {
                    id: 2,
                    title: "Przypadki użycia",
                    desc:  "Analizujemy przypadki użycia aplikacji, aby upewnić się, że projekt spełnia wszystkie wymagania funkcjonalne."
                },
                {
                    id: 3,
                    title: "Projekt graficzny",
                    desc:  "Nasz zespół projektowy tworzy atrakcyjne i funkcjonalne projekty graficzne, które doskonale odzwierciedlają potrzeby i oczekiwania klienta."
                }
            ]
        },
        {
            id:4,
            title: "Implementacja",
            image: iconWeb,
            header: "Implementacja",
            desc: "Po zatwierdzeniu projektu przystępujemy do etapu implementacji. Nasz zespół doświadczonych programistów i developerów podejmuje się kodowania i tworzenia aplikacji zgodnie z wcześniej ustalonymi specyfikacjami.",
            paragraphsArray:[
                {
                    id: 1,
                    title: "Programowanie i testowanie",
                    desc:  "Stawiamy na jakość kodu, modularność i skalowalność, aby zapewnić naszym klientom produkt, który spełnia ich oczekiwania i potrzeby."
                },
                {
                    id: 2,
                    title: "Iteracyjne ulepszanie",
                    desc:  "W trakcie procesu regularnie raportujemy postępy, aby zachować transparentność i umożliwić klientom śledzenie rozwoju projektu."
                }
            ]
        },
        {
            id:5,
            title: "Wdrożenie i Utrzymanie",
            image: icoImplementationAndMaintenance,
            header: "Wdrożenie i Utrzymanie",
            desc: "Po zakończeniu procesu implementacji przystępujemy do wdrażania aplikacji w środowisku produkcyjnym. Zapewniamy kompleksowe wsparcie podczas tego kluczowego etapu, dbając o to, aby migracja była płynna i bezproblemowa.",
            paragraphsArray:[
                {
                    id: 1,
                    title: "Wsparcie techniczne",
                    desc:  "Nasz zespół pozostaje dostępny, aby szybko reagować na ewentualne problemy i zapewnić ciągłą dostępność i funkcjonalność aplikacji dla naszych klientów."
                },
                {
                    id: 2,
                    title: "Aktualizacje i utrzymanie",
                    desc:  "Regularnie dokonujemy aktualizacji i utrzymania aplikacji, zapewniając jej wysoką jakość i bezpieczeństwo na dłuższą metę."
                }
            ]
        }
    ],
    ProjectsTitle:{
        header: "Zrealizowane przez nas projekty"
    },
    OurTechnologiesTitle:{
        header: "Technologie których używamy"
    },
    Technologies:{
        images:[
            react,
            typeScript,
            javaScript,
            mongoDb,
            cassandra,
            cpp,
            tsql,
            mysql,
            net,
            csharp,
            android,
            kotlin,
            spring,
            java
        ]
    },
    Project: ProjectsData.Projects
}
