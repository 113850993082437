import React from 'react';
import { useField , ErrorMessage } from 'formik';
import ReactHtmlParser from 'react-html-parser';

const CheckboxField = ({...props}) => {
    const [ field  ] = useField(props);
    return (
        <div className="form-group">
            <div className="Checkbox--custom form-check">
                <input 
                name={props.name}
                type={props.type}
                className="form-check-input Checkbox--input"
                {...field}{...props}
                />
                <label 
                className="form-check-label"
                >
                {ReactHtmlParser(props.text)}
                </label>
            </div>
            <ErrorMessage component="div" name={field.name} className='Display--error'/>
        </div>
    )
}

export default CheckboxField
