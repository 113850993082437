import React from 'react'
import styled from 'styled-components'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import AppearDiv from '../elements/AppearDiv'
import CustomLink from '../elements/CustomLink'

const FooterContainer = styled.div`
    display: flex;
    margin-top: 65px;
    margin-bottom: 25px;
    border-top: solid 1px #F2F2F2;
    padding-top: 35px;

    @media (max-width: 575px) {
        flex-direction: column;
    }
`

const CompanyInformationContainer = styled.div`
    @media (max-width: 575px) {
        width: 100%;
    }

    @media (min-width: 576px){
        width: 550px;
    }
`

const FooterItemTitle = styled.p`
    font-weight: 500;
`;

const FooterText = styled.p`
    margin-bottom: 5px;
`;

const PagesLinkContainer = styled(Row)`
    width: 100%;
`

const PagesLinkCol = styled(Col)`
    @media (max-width: 575px) {
        margin: 12.5px 0px;
    }
`

const Footer = () => {

    return (
        <AppearDiv>
            <FooterContainer>
                <CompanyInformationContainer>
                    <FooterItemTitle>{"NECODEM SP. Z O.O."}</FooterItemTitle>
                    <FooterText>{"66-008 Drzonów 20"}</FooterText>
                    <FooterText>{"tel.: +48 531 480 234"}</FooterText>
                    <FooterText>{"e-mail: necodem.com@gmail.com"}</FooterText>
                </CompanyInformationContainer>
                <PagesLinkContainer>
                    <PagesLinkCol md sm={5} xs={7}>
                        <CustomLink 
                            data={{ link: "/usługi", linkText: "Usługi" }} 
                            color={'black'} 
                            isIcon={false} 
                            bold={"true"}
                        />
                    </PagesLinkCol>
                    <PagesLinkCol md sm={5} xs={7}>
                        <CustomLink 
                            data={{ link: "/realizacje", linkText: "Realizacje" }} 
                            color={'black'} 
                            isIcon={false} 
                            bold={"true"}
                        />
                    </PagesLinkCol>
                    <PagesLinkCol md sm={5} xs={7}>
                        <CustomLink 
                            data={{ link: "/kontakt", linkText: "Kontakt" }} 
                            color={'black'} 
                            isIcon={false} 
                            bold={"true"}
                        />
                    </PagesLinkCol>
                    <PagesLinkCol md sm={5} xs={7}>
                        <CustomLink 
                            data={{ link: "/polityka-prywatności", linkText: "Polityka prywatności" }} 
                            color={'black;'} 
                            isIcon={false} 
                            bold={"true"}
                        />
                    </PagesLinkCol>
                </PagesLinkContainer>
            </FooterContainer>
        </AppearDiv>
    )
}

export default Footer
