import React , { useState } from 'react';
import Button from './Button';
import Project from './Project';
import styled from 'styled-components';
import AppearDiv from '../elements/AppearDiv';

const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;

    @media (max-width: 600px) {
        & > * {
            flex-basis: 100%;
            margin-bottom: 15px;
        }
    }

    @media (max-width: 1000px)  {
        margin-bottom: 10px;
        flex-wrap: wrap;
    }

    @media (min-width: 601px) and (max-width: 1000px) {
        & > * {
            flex-basis: calc(50% - 10px);
            margin-bottom: 10px;
        }
    }
`;

const ProjectsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
`;

const ProjectsDetails = (props) => {

    const [numberOfSelectedButton, setNumberOfSelectedButton] = useState(0);

    function handleClick(event){
        if(event.target.dataset.id !== undefined){
            setNumberOfSelectedButton(event.target.dataset.id)
         }
    }

    const buttonsList = props.data.buttons.map((item, index) =>
            <Button 
                key={index}
                text={item.text} 
                imageSrc={item.imageColorSrc}
                activeImageSrc={item.imageWhiteSrc}
                isSelectable={true} 
                id={item.id} 
                active={parseInt(numberOfSelectedButton)}/>
    )

    const projectsList = props.data.projectsArray.filter(p => p.type.includes(parseInt(numberOfSelectedButton))).map((item, index) =>
        <Project 
            data={item} key={index}/>
    )

    return (
        <AppearDiv>
            <ButtonWrapper onClick={handleClick}> 
                {buttonsList}
            </ButtonWrapper>
            <ProjectsContainer>
                {projectsList}
            </ProjectsContainer>
        </AppearDiv>
    )
}

export default ProjectsDetails
