import React from 'react'
import Main from '../components/Main'

const ServicesDetails = (props) => {

    let servicesList = props.data.map((item, index) =>
        <Main 
            key={index} 
            data={item}
        />
    )

    return (
        <div>
            {servicesList}
        </div>
    )
}

export default ServicesDetails
