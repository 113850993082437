import { ThemeProvider } from "styled-components"
import React from "react"

/*generator do kolorów dla icon hover*/
// https://codepen.io/sosuke/pen/Pjoqqp

const theme = {
    colors: {
        darkBlue: '#2350e0 !important;',
        white: 'white !important;'
    }
}

const Theme = ({children}) => (
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;