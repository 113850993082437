import React from 'react';
import styled from 'styled-components';
import AppearDiv from '../elements/AppearDiv';

const MockupContainer = styled.div`
    margin-top: 75px;
    width: 100%;
    width: 100%;
    display: block;
`;

const MockupImage = styled.img`
    border-radius: 10px;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const Mockup = ({ mockup }) => {
      
    return (
        <AppearDiv>
            <MockupContainer>
                <MockupImage
                    src={mockup.src}
                    alt={mockup.alt}/>
            </MockupContainer>    
        </AppearDiv>
    )
}

export default Mockup
