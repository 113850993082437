import React , { useState, useEffect, useRef } from 'react'
import useScrollPosition from '@react-hook/window-scroll'
import styled from 'styled-components'

const MainDiv = styled.div`
  transition: opacity 2.00s;
  opacity: ${props => props.opacity};
  height: auto;
`

const AppearDiv = (props) => {

    const scrollY = useScrollPosition(10);
    const divRef = useRef();
    const [isRun, setRun] = useState(true);
    const [opacity, setOpacity] = useState(0);
    
    useEffect(() => {
        if(isRun){
            let pointOfVisibility = scrollY+window.innerHeight;
            if(pointOfVisibility > divRef.current.offsetTop){
                setOpacity(1)
                return setRun(false);
            }
        }
    }, [scrollY, isRun]);
    
    return (
        <MainDiv ref={divRef} opacity={opacity}>
            {props.children}
        </MainDiv>
    )
}

export default AppearDiv
