import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { IoIosArrowForward } from "react-icons/io"

const LinkComponent = styled(Link)`
    text-decoration: none !important;
    color: ${props => (props.color ? props.color : props.theme.colors.darkBlue)};
    transition:  0.3s;
    font-weight: ${props => (props.bold  ? '500;' : 'normal;')};
    &:hover {
        margin-right: 5px;
        transition:  0.3s;
    }
`;
const Container = styled.div`
    svg{
        font-weight: 500;
        color: ${props => (props.color ? props.color : props.theme.colors.darkBlue)}
    }
`
const CustomLink = (props) => {
    return (
        <Container color={props.color}>
            <LinkComponent bold={props.bold} color={props.color} to={props.data.link}>{props.data.linkText}
                </LinkComponent>
                {props.isIcon &&  <IoIosArrowForward/>}
        </Container>
    )
}

export default CustomLink
