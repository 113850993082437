import React from 'react'
import {PrivacyPolicyData} from '../data/PrivacyPolicyData'
import Title from '../components/Title'
import Terms from '../components/Terms'
import PageContainer from './PageContainer'

const PrivacyPolicy = () => {

    return (
        <PageContainer>
            <Title data={PrivacyPolicyData.Title}/>
            <Terms data={PrivacyPolicyData.Contents}/>
        </PageContainer>
    )
}

export default PrivacyPolicy
