import { motion } from "framer-motion";
import NavigationBar from "../components/NavigationBar";
import Footer from "../components/Footer";
import { Container } from "react-bootstrap";
import React , {useEffect} from 'react'

const PageContainer = ( { children  } ) => {
        
    useEffect(() => {
        window.scrollTo(0, 0)
    },[]);

    return(
        <>
            <NavigationBar/>
            <motion.div
            initial={{ opacity: 0}}
            animate={{ opacity: 1}}
            exit={{ opacity: 0}}
            >
            <Container fluid="xl">
                { children  }
                <Footer/>
            </Container>
            </motion.div>
        </>
    )
}

export default PageContainer