import React , { useState , useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const ButtonStyled = styled.button`
    padding-left: 20px; 
    padding-right: 20px; 
    height: 60px;
    background-color: ${props => props.isSelected ? props.theme.colors.darkBlue : props.theme.colors.white };
    border: 2px solid ${props => props.theme.colors.darkBlue};
    color: ${props => props.theme.colors.darkBlue};
    transition: all 0.50s;
    &:hover {
        color: ${props => props.theme.colors.white};
        background-color: ${props => props.theme.colors.darkBlue};
    }
`

const ButtonStyledWithIcon = styled(ButtonStyled)`
    display:flex;
    justify-content:center;
    align-items:center;
    color: ${props => props.isSelected ? props.theme.colors.white : '#212529 !important;' };
    
    @media(max-width: 1000px){
        width: 100%;
        margin-bottom: 5px;
    }
`

const IconBox = styled.div`
    width: 48px;
    height: 48px;
    margin-right: 10px;
    margin-top: 5px;
    float:left;
`

const Button = (props) => {
    const [ isSelected, setSelected ] = useState(false);
    const [ isHovered, setIsHovered ] = useState(false);

    function changeState() { 
        if(props.active === undefined){
            if(props.isSelectable === true){
                setSelected(!isSelected)
            } else {
                setSelected(true)
            }
        }
    }

    useEffect(() => {
        if(props.active !== undefined){
            if(props.active === props.id){
                setSelected(true)
            }
            else if(props.active !== props.id){
                setSelected(false)
            }
        }
    }, [props.active, props.id]);

    if(props.imageSrc){
        return (
            <div onClick={changeState}>
                <ButtonStyledWithIcon 
                    isSelected={isSelected} 
                    data-id={props.id}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}>

                    <IconBox isSelected={isSelected || isHovered}>
                        <input 
                            type="image" 
                            src={isSelected || isHovered ? props.activeImageSrc : props.imageSrc} 
                            width="40px" 
                            height="40px" 
                            data-id={props.id} 
                            alt="icon"/>
                    </IconBox>

                    {props.text}

                </ButtonStyledWithIcon>
            </div>
        )
    } else {
        return (
            <div onClick={changeState}>
                { props.link  &&  
                <Link to={props.link}>
                    <ButtonStyled isSelected={isSelected}>{props.text}</ButtonStyled>
                </Link>
                }
                { !props.link && <ButtonStyled type={props.submit ? 'submit' : 'button'}>{props.text}</ButtonStyled>}


            </div>
        )
    }
}

export default Button

