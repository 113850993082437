//prawojazdy.pro
import prawojazdyProHeader from '../images/projects/prawojazdy.pro/header.png';
import prawojazdyProPhonesMockup from '../images/projects/prawojazdy.pro/phones.png';

//technologies
import react from "../images/technologies/react.svg"
import javaScript from "../images/technologies/javascript.svg"
import mysql from "../images/technologies/mysql.svg"
import kotlin from "../images/technologies/kotlin.svg"
import android from "../images/technologies/android.svg"
import java from "../images/technologies/java.svg"
import spring from "../images/technologies/spring.svg"

export const ProjectData = {
    ParamasUrl:[
        'prawojazdy.pro'
    ],
    Projects:[
        {
            Main:{
                header:'Prawojazdy.pro',
                desc:'Platforma e-learningowa przeznaczona dla osób przygotowujących się do egzaminu na prawo jazdy.',
                img: prawojazdyProHeader,
                backgroundNumber: '01',
                // dataLink:{
                //     link: '/realizacje/roomMate',
                //     linkText: 'Następny projekt'
                // }
            },
            Title:{
                header: "Opis projektu",
                desc: "Prawojazdy.pro to wiodąca aplikacja wspierająca kandydatów na kierowców w teoretycznej nauce przepisów drogowych, znaków oraz odpowiedniego zachowania w różnorodnych sytuacjach drogowych."
            },
            Description:{
                Left:{
                    header: "Wymagania",
                    desc: "Nasz zespół podjął się ambitnego zadania stworzenia kompleksowego systemu aplikacji od podstaw. Rozpoczęliśmy od projektowania grafiki oraz interfejsów użytkownika, aby zapewnić przyjazne i intuicyjne doświadczenie dla użytkowników. Następnie przystąpiliśmy do doboru odpowiednich technologii, które umożliwią sprawne działanie aplikacji na różnych platformach."  + 
                          "<br/><br/>Opracowaliśmy również aplikację mobilną, aby użytkownicy mieli łatwy dostęp do materiałów edukacyjnych na swoich urządzeniach przenośnych. Dodatkowo, skupiliśmy się na stworzeniu solidnej części serwerowej, zapewniającej bezpieczne przetwarzanie danych oraz płynne funkcjonowanie całego systemu." + 
                          "<br/><br/>Nie zapomnieliśmy także o wizytówce w postaci strony internetowej, która pełni kluczową rolę w promocji naszego produktu oraz umożliwia łatwy kontakt z potencjalnymi użytkownikami. Nasze zaangażowanie nie kończy się jednak na etapie wdrożenia – zapewniamy także ciągłe utrzymanie serwisu, dbając o jego stabilność i rozwój, aby nasi użytkownicy zawsze mieli najlepsze doświadczenie z naszą aplikacją.",
                },
                Right:[
                    {
                        id: '1',
                        title: 'UI/UX',
                        desc: 'Naszym celem było zapewnienie, aby interfejs użytkownika w naszej aplikacji dostarczał użytkownikom niezapomnianych wrażeń. Tworzyliśmy atrakcyjne widoki, które nie tylko przyciągały wzrok, ale także zapewniały intuicyjną obsługę, aby każdy mógł korzystać z aplikacji bez problemów.'
                    },
                    {
                        id: '2',
                        title: 'Jakość',
                        desc: 'Priorytetem dla nas było stworzenie aplikacji charakteryzującej się nie tylko wysoką wydajnością, ale także niezawodnością. Dzięki starannie dobranym technologiom natywnym udało nam się osiągnąć nasz cel, tworząc solidne fundamenty dla dalszego rozwoju i rozbudowy aplikacji.'
                    },
                    {
                        id: '3',
                        title: 'Ocena',
                        desc: 'Nasza aplikacja w Google Play Store cieszy się ogromnym uznaniem użytkowników. Zdobyła wysokie oceny oraz liczne pozytywne opinie. Mimo osiągniętych sukcesów, nieustannie dążymy do doskonalenia naszego produktu. Naszym celem jest ciągły rozwój aplikacji, wprowadzanie nowych funkcji i usprawnień, aby zapewnić naszym użytkownikom najlepsze możliwe doświadczenia z korzystania z naszej platformy.'
                    },
                ]
            },
            TechnologiesTitle:{
                header: "Technologie użyte w projekcie"
            },
            Technologies:{
                images:[
                    react,
                    javaScript,
                    mysql,
                    kotlin,
                    android,
                    java,
                    spring
                ]
            },
            Mockup: {
                src: prawojazdyProPhonesMockup,
                alt: 'wizualizacja aplikacji'
            }
        }
    ]
}