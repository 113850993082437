import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import styled from 'styled-components'
import Button from '../components/Button.js'
import CustomLink from '../elements/CustomLink'
import { device  } from '../device'
import AppearDiv from '../elements/AppearDiv'

const RowFullView = styled(Row)`
    display: flex;
    align-items: center;
    margin-bottom: ${props => (props.fullview ? '0px;': '160px;')};
    flex-direction: ${props => (props.reversed ? 'row;' : 'row-reverse;')};

    @media (max-width: 767px) and (min-height: 610px) {
        height: 80vh;
    }

    @media (min-width: 768px) and (max-width: 2400px) {
        height: ${props => (props.fullview ? '100%;' : 'calc(100vh - 160px);')};
    }

    @media (min-width: 2400px){
        height: 100%;
        margin: 150px 0px;
    }
`;

const BackgroundNumber = styled.p`
    font-size: 134px;
    font-weight: bold;
    color: ${props => props.theme.colors.darkBlue};
    opacity: 0.5;
    bottom: 76px;
    position: absolute;
    @media (min-width: 1250px){
        left: -48px;
    }
    @media ${device.tablet}{
        left: 0px;
    }
    @media ${device.mobileL}{
        left: 0px;
        bottom: 120px;
    }
    @media ${device.mobileM}{
        left: 0px;
        bottom: 146px;
    }
    @media ${device.mobileS}{
        left: 0px;
        bottom: 182px;
    }
`

const FirstCol = styled(Col)`
    order: 1;
    @media (max-width: 767px){
        order: 2;
    }
`

const SecondCol = styled(Col)`
    order: 2;
    @media (max-width: 767px){
        order: 1;
    }
`
const Description = styled.p`
    margin-top: 0.5rem;
    margin-bottom: 1rem;
`

const Main = (props) => {

    return (
        <AppearDiv>
        <RowFullView reversed={Boolean(props.data.reversed)} fullview={props.data.fullview}>
            <FirstCol md={5}>
                    {
                        props.data.backgroundNumber && 
                        <BackgroundNumber>{props.data.backgroundNumber}</BackgroundNumber>
                    }

                    <h2>{props.data.header}</h2>

                    <Description>{props.data.desc}</Description>

                    { 
                        props.data.dataLink && 
                        <CustomLink 
                            data={props.data.dataLink} 
                            color={'black !important;'} 
                            isIcon={true} 
                            bold={'true'}/>
                    }

                    {
                        props.data.button &&
                        <Button 
                            text={props.data.button.text} 
                            link={props.data.button.link}/>
                    }
            </FirstCol>

            <SecondCol md={7}>
                <Image src={props.data.img} fluid />
            </SecondCol>

        </RowFullView>
        </AppearDiv>
    )
}

export default Main
