import React from 'react'
import styled from 'styled-components'

const ParagraphContainer = styled.div`
    display:flex;
`;
const Number = styled.p`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.9em;
    color: ${props => props.theme.colors.darkBlue}
    margin-right: 35px;
    font-weight: 500;
`;

const Paragraph = (props) => {

    return (
            <ParagraphContainer>
                    <Number>
                        {props.data.id}
                    </Number>
                    <div>
                        <h5>{props.data.title}</h5>
                        <p>{props.data.desc}</p>
                    </div>
            </ParagraphContainer>
    )
}

export default Paragraph
