import {MainPageData} from '../data/MainPageData'
import Main from '../components/Main'
import Title from '../components/Title'
import Services from '../components/Services'
import HowWeWorks from '../components/HowWeWorks'
import Project from '../components/Project'
import OurTechnologies from '../components/OurTechnologies'
import AppearDiv from '../elements/AppearDiv'
import PageContainer from './PageContainer'


const MainPage = () => {
    return (
        <PageContainer>
            <Main data={MainPageData.Main}/>

            <Title data={MainPageData.ServicesTitle}/>
            <Services data={MainPageData.Services}/>

            <Title data={MainPageData.HowWeWorksTitle}/>
            <HowWeWorks data={MainPageData.HowWeWorksPoints}/>
            <br/>

            <Title data={MainPageData.OurTechnologiesTitle}/>
            <OurTechnologies data={MainPageData.Technologies}/>
            <br/>

            <Title data={MainPageData.ProjectsTitle}/>
            <AppearDiv>
                <Project data={MainPageData.Project}/>
            </AppearDiv>
        </PageContainer>
    )
}

export default MainPage
