import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';
import CustomLink from '../elements/CustomLink';
import ReactHtmlParser from 'react-html-parser';

const IconColumnContainer = styled.div`
    margin-bottom: 50px;
    width: 100%;
`;

const IconColumn = (props) => {
    return (
        <IconColumnContainer>
            {
                props.data.image && 
                <Icon 
                    image={props.data.image} 
                    colorfull={"true"}/>
            }
            <h5>{props.data.header}</h5>
            <p>{ReactHtmlParser(props.data.desc)}</p>
            { 
                props.data.link && 
                <CustomLink 
                    data={props.data} 
                    isIcon={true} 
                    bold={'true'}/>
            }
        </IconColumnContainer>
    )
}

export default IconColumn
