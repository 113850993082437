import React from 'react';
import { useField , ErrorMessage } from 'formik';
import '../styles/textfields.css';

const AreaTextField = ({ ...props}) => {
    const [field , meta] = useField(props);
    return (
        <div className="form-group">
            <label className="form-label Label--custom">
                {props.label}
            </label>
            <textarea
            className={ meta.touched && meta.error ? 'Textarea--custom form-control is-invalid' : 'Textarea--custom form-control'}
            type={props.type}
            {...field}{...props}
            />
            <ErrorMessage component="div" name={field.name} className='Display--error'/>
        </div>
    )
}

export default AreaTextField
