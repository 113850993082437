import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import IconColumn from '../elements/IconColumn'
import AppearDiv from '../elements/AppearDiv'

const Services = (props) => {

    let iconColumnList = props.data.map((item) =>
        <Col md key={item.id}>
            <IconColumn data={item} key={item.id}></IconColumn>
        </Col>
    )

    return (
        <AppearDiv>
            <Row>
                {iconColumnList}
            </Row>
        </AppearDiv>
    )
}

export default Services
