import React from 'react'
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components'

const CenterHeader = styled.h6`
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
`
const CustomParagraph = styled.p`
    b{
        font-weight: 500;
        text-align: center;
    }
`

const TermParagraph = (props) => {

    return (
        <>
            <CenterHeader>{props.data.header}</CenterHeader>
            {props.data.desc.map((item) => 
            <CustomParagraph key={item}>
                {ReactHtmlParser(item)}
            </CustomParagraph>
            )}
        </>
    )
}

export default TermParagraph
