import React , { useState ,useEffect, useCallback } from 'react'
import Main from '../components/Main'
import Title from '../components/Title'
import { ProjectData } from '../data/ProjectData'
import Description from '../components/Description'
import OurTechnologies from '../components/OurTechnologies'
import Mockup from '../components/Mockup'
import { useParams , useHistory  } from "react-router-dom"
import styled from 'styled-components'
import PageContainer from './PageContainer'

const MainDiv = styled.div`
  transition: opacity 1.00s;
  opacity: ${props => props.opacity};
`

const Project = () => {
    const { id } = useParams();
    const history = useHistory();
    const [ selectedProjectId , setSelectedProjectId ] = useState(0);
    const [ opacity, setOpacity ] = useState(0);

    const getProjectById = useCallback(() => {
        setOpacity(1);
        let index = ProjectData.ParamasUrl.indexOf(id);
        if (index === -1)
            history.push("/");
        if(selectedProjectId !== index){
            setOpacity(0)
            setTimeout(() => {
                setSelectedProjectId(index)
                setOpacity(1)
            }, 1000);
        }
    },[history, id, selectedProjectId])

    useEffect(() => {
        getProjectById();
    }, [id, getProjectById])

    return (
        <PageContainer>
              <MainDiv opacity={opacity}>
                <Main data={ProjectData.Projects[selectedProjectId].Main}/>
                <Title data={ProjectData.Projects[selectedProjectId].Title}/>
                <Description data={ProjectData.Projects[selectedProjectId].Description}/>
                <Title data={ProjectData.Projects[selectedProjectId].TechnologiesTitle}/>
                <OurTechnologies data={ProjectData.Projects[selectedProjectId].Technologies}/>
                <Mockup mockup={ProjectData.Projects[selectedProjectId].Mockup}/>
              </MainDiv>
        </PageContainer>
    )
}

export default Project
