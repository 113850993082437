import React , { useState , useEffect } from 'react'
import styled from 'styled-components'
import { device } from '../device'

const Circle = styled.div`
    cursor: pointer;
    height: 76px;
    width: 76px;
    background-color: ${props => props.isSelected ?  props.theme.colors.darkBlue : '#FFFFFF;'};
    border-radius: 50%;
    border: solid 3px ${props => props.theme.colors.darkBlue};
    transition:  0.5s;
    display: inline-block;
    &:hover {
        background-color: ${props => props.theme.colors.darkBlue};
        transition:  0.5s;       
        p{
            color:#FFFFFF !important;
        }
    }
    @media (max-width: 575px){
        height: 50px;
        width: 50px;
    }

`;
const Number = styled.p`
    font-weight: 500;
    color: ${props => props.isSelected ? '#FFFFFF;' : props.theme.colors.darkBlue };
    transition:  0.3s;
    position: relative;
    @media (max-width: 575px){
        font-size: 1.5em;
        left: 15px;
        top: 5px;
    }
    @media (min-width: 576px){
        font-size: 1.9em;
        left: 28px;
        top: 11px;
    }
`;
const Header = styled.h5`
    text-align: center;
    @media ${device.tablet}{
        display: none;
    }
`;

const PointLine = (props) => {

    const [ isSelected, setSelected ] = useState(false)

    useEffect(() => {

        if(props.active === props.data.id){
            setSelected(true)
        }
        if(props.active !== props.data.id){
            setSelected(false)
        }

    }, [props.data.id, props.active]);

    return (
        <>
            <Circle isSelected={isSelected} data-id={props.data.id} >
                <Number isSelected={isSelected} data-id={props.data.id} >{props.data.id}</Number>
            </Circle>
            <Header>{props.data.title}</Header>
        </>
    )
}

export default PointLine
