import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import MainPage from './page/MainPage'
import ProjectsList from './page/ProjectsList'
import Theme from './Theme'
import Project from './page/Project'
import ServicesList from './page/ServicesList'
import Service from './page/Service'
import Contact from './page/Contact'
import { AnimatePresence } from "framer-motion"
import PrivacyPolicy from './page/PrivacyPolicy'
import NotFound from './page/NotFound'

function App() {

  return (
    <>
        <Theme>
          <Router>
                <AnimatePresence>
                  <Switch>
                    <Route path='/' exact component={MainPage} />
                    <Route path='/realizacje/:id' exect component = {Project} />
                    <Route path='/realizacje' exect component = {ProjectsList} />
                    <Route path='/usługi/:id' exect component = {Service} />
                    <Route path='/usługi' exect component = {ServicesList} />
                    <Route path='/kontakt' exect component = {Contact} />
                    <Route path='/polityka-prywatności' exect component = {PrivacyPolicy} />
                    <Route component={NotFound} />
                  </Switch>
                </AnimatePresence>
          </Router>
        </Theme>
    </>
  );
}

export default App;
