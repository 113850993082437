import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import IconColumn from '../elements/IconColumn'
import AppearDiv from '../elements/AppearDiv'

const ServicesAdvantages = (props) => {

    let servicesAdvantagesFirstList  = props.data[0].map((item) =>
    <Col md key={item.id}>
        <IconColumn data={item}></IconColumn>
    </Col>
    )

    let servicesAdvantagesSecondList  = props.data[1].map((item) =>
    <Col md key={item.id}>
        <IconColumn data={item}></IconColumn>
    </Col>
    )

    return (
        <AppearDiv>
            <Row>
                {servicesAdvantagesFirstList}
            </Row>
            <Row>
                {servicesAdvantagesSecondList}
            </Row>
        </AppearDiv>
    )
}

export default ServicesAdvantages
