import React from 'react';
import styled from 'styled-components';
import Slider from "react-slick";
import AppearDiv from '../elements/AppearDiv';
import "../styles/slick-slider.css";

const SliderBox = styled.div`
  width: 100%;
  margin: 0 auto;
  height: 70px;
`

const Slide = styled.div`
  transition: all 5000ms;
  filter: invert(0%) sepia(100%) saturate(30%) hue-rotate(59deg) brightness(89%) contrast(106%);

  &:hover {
    filter: none;
    transition: all 500ms;
  }
`

const SlideImage = styled.img`
  height: 70px;
` 

const OurTechnologies = (props) => {

    const settings = {
        infinite: true,
        lazyLoad: "progressive",
        speed: 2000,
        slidesToShow: props.data.images.length < 7 ? 4 : 7,
        centerMode: true,
        centerPadding: 0,
        autoplaySpeed: 500,
        autoplay: true,
        waitForAnimate: true,
        arrows: false,
        dots: false,
        rtl: true,
        pauseOnDotsHover: false,
        pauseOnFocus: false,
        pauseOnHover: false,
        focusOnSelect: false,
        swipeToSlide: true,
        swipe: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4
                }
            }
        ]
    };

    return (
        <AppearDiv>
            <SliderBox>
                <Slider {...settings}>
                    {props.data.images.map((img, index) => (
                        <Slide key={index}>
                            <SlideImage src={img} alt={img} />
                        </Slide>
                    ))}
                </Slider>
            </SliderBox>
        </AppearDiv>
    );
}

export default OurTechnologies

