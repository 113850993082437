import React from 'react'
import {NotFoundData} from '../data/NotFoundData'
import Main from '../components/Main'
import PageContainer from './PageContainer';

const NotFound = () => {
    return (
        <PageContainer>
            <Main data={NotFoundData.Main}/>
        </PageContainer>
    )
}

export default NotFound
