import React from 'react'
import Main from '../components/Main'
import Title from '../components/Title'
import { ServicesListData } from '../data/ServicesListData'
import ServicesDetails from '../components/ServicesDetails'
import PageContainer from './PageContainer'

const ServicesList = () => {

    return (
        <PageContainer>
            <Main data={ServicesListData.Main}/>
            <Title data={ServicesListData.ServicesTitle}/>
            <ServicesDetails data={ServicesListData.ServicesDetails}/>
        </PageContainer>
    )
}

export default ServicesList
