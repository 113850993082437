import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import styled from 'styled-components'
import contactImage from '../images/main-images/Contact.svg'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import TextField from '../elements/TextField'
import AreaTextField from '../elements/AreaTextField'
import Button from '../components/Button.js'
import { size } from '../device'
import { Alert } from 'react-bootstrap'
import { motion } from "framer-motion"
import AppearDiv from '../elements/AppearDiv'
import CheckboxField from '../elements/CheckboxField'

/*
const RowFullView = styled(Row)`
    display: flex;
    align-items: center;
    height: calc(100vh - 160px);
    margin-bottom: 160px;
    @media ${device.tablet}{
        margin-bottom:80%;
    }
`;

const FirstCol = styled(Col)`
    order: 1;
    @media ${device.tablet}{
        order: 2;
        left:10%;
    }
    @media ${device.mobileL}{
        left: 10px;
    }
    @media ${device.mobileM}{
        left: 0px;
    }
    @media ${device.mobileS}{
        left: 0px;
    }
`

const AlertStyled = styled(Alert)`
    width:320px;
`

const SecondCol = styled(Col)`
    order: 2;
    
    @media ${device.tablet}{
        order: 1;
    }
`
*/

const RowFullView = styled(Row)`
    display: flex;
    align-items: center;   
    margin-bottom: 160px;

    @media (min-width: ${size.tablet}){
        height: calc(100vh - 160px);
    }
`;

const FirstCol = styled(Col)`
    @media (max-width: 767px){
        order: 2;
    }

    @media (min-width: ${size.tablet}){
        order: 1;
    }
`

const AlertStyled = styled(Alert)`
    @media (min-width: ${size.tablet}){
        width:320px;
    }
`

const SecondCol = styled(Col)`
    @media (max-width: 767px){
        order: 1;
    }

    @media (min-width: ${size.tablet}){
        order: 2;
    }
`

const ContactMain = () => {

    const [isSuccess, setIsSuccess] = useState(false)
    const [isError, setIsError] = useState(false)
    let privacyPolicy = 'Oświadczam, że zapoznałem się z <a href="/polityka-prywatności">polityką prywatności</a> serwisu'


    const validate = Yup.object({
        fullName: Yup.string()
            .required('Pole musi być uzupełnione'),
        email: Yup.string()
            .email('Adres email jest nie poprawny')
            .required('Adres email jest wymagany'),
        message: Yup.string()
            .required('Pole musi być uzupełnione'),
        checkboxPrivacyPolicy: Yup.boolean()
            .oneOf([true], 'Wymagana jest akceptacja polityki prywatności')
            .required('Wymagana jest akceptacja polityki prywatności') 
    })


    function clearForm() {
        document.getElementById("contactForm").reset();
    }

    async function sendEmail(values){
        setIsSuccess(false);
        setIsError(false);
        fetch('/mail', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(values),
        })
        .then(response => response.json())
        .then(data => {
          console.log('Success:', data);
          setIsSuccess(true);
          clearForm();
          setIsError(false);
        })
        .catch((error) => {
          console.error('Error:', error);
          setIsError(true);
          setIsSuccess(false);
        });
    }

   
        
    return (
        <AppearDiv>
        <RowFullView>
            <FirstCol md={6}>
                <Formik
                    initialValues={{
                        fullName:'',
                        email:'',
                        message:'',
                        checkboxPrivacyPolicy:''
                    }}
                    validationSchema={validate}
                    onSubmit={values =>{
                        sendEmail(values)
                    }}
                >
                    {formik => (
                        <>
                        <h2>Skontaktuj się z nami</h2>
                        <p>Daj nam znać, w czym możemy Ci pomóc.</p>
                        <motion.div
                            initial={{ opacity: 0}}
                            animate={{ opacity: 1}}
                            exit={{ opacity: 0}}  
                        >
                        <AlertStyled show={isError} variant="danger">Nie udało się wysłać wiadomości.<br/> Prosimy spróbować później.</AlertStyled>
                        <AlertStyled show={isSuccess} variant="primary">Wiadomość wysłana</AlertStyled>
                        </motion.div>
                        <Form id="contactForm">
                                <TextField label="Imię i nazwisko" name="fullName"/>
                                <TextField label="E-mail" name="email"/>
                                <AreaTextField label="Wiadomość" name="message"/>
                                <CheckboxField name="checkboxPrivacyPolicy" type="checkbox" text={privacyPolicy}/>
                                <br />
                                <Button text="Wyślij wiadomość" isSelectable="false" submit></Button>
                        </Form>
                        </>
                    )}
                </Formik>
            </FirstCol>
            <SecondCol md={6}>
                <Image src={contactImage} fluid />
            </SecondCol > 
        </RowFullView>
        </AppearDiv>
    )
}

export default ContactMain
