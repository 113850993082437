import React from 'react'
import styled from 'styled-components'
import AppearDiv from '../elements/AppearDiv'

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    font-weight: 400;
    margin-bottom: 50px;
    margin-top: 50px;
    text-align:center;
    justify-content: center;
    p , h3 {
        max-width: 760px;
    }
`;

const Title = (props) => {
    return (
        <AppearDiv>
            <TitleContainer>
                <h3>{props.data.header}</h3>
                {props.data.desc && <p>{props.data.desc}</p>}
            </TitleContainer>
        </AppearDiv>
    )
}

export default Title
