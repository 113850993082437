import React from 'react'
import styled from 'styled-components'
import Image from 'react-bootstrap/Image'

const IconSVG = styled(Image)`
    height: 67px;
    width: 67px;
    margin-bottom: 22px;
    filter: ${props => props.colorfull ? 'none' : props.theme.colors.iconHoverColor  };
`;

const Icon = (props) => {

    return (
        <IconSVG src={props.image} colorfull={props.colorfull} fluid/>
    )
}

export default Icon
