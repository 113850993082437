import React from 'react'
import styled from 'styled-components'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import IconColumn from '../elements/IconColumn'
import Paragraph from '../elements/Paragraph'
import { device } from '../device'
import AppearDiv from '../elements/AppearDiv'

const CustomRow = styled(Row)`
    margin-top: 70px;

    @media ${device.tablet}{
        flex-direction: column;
        display: flex;
        flex-grow: 1;
    }
`;

const Description = (props) => {
    return (
        <AppearDiv>
            <CustomRow>
                <Col>
                    <IconColumn data={props.data.Left}></IconColumn>
                </Col>
                <Col>
                    {
                        props.data.Right.map((item) =>
                        <Paragraph data={item} key={item.id}/>
                        )
                    }
                </Col>
            </CustomRow>
        </AppearDiv>
    )
}

export default Description
