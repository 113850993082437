import React from 'react'
import Main from '../components/Main'
import { ProjectsListData } from '../data/ProjectsListData'
import Title from '../components/Title'
import ProjectsDetails from '../components/ProjectsDetails'
import PageContainer from './PageContainer'

const ProjectsList = () => {
    
    return (
        <PageContainer>
            <Main data={ProjectsListData.Main}/>
            <Title data={ProjectsListData.ProjectsListTitle}/>
            <ProjectsDetails data={ProjectsListData.ProjectsDetails}/>
        </PageContainer>
    )
}

export default ProjectsList
