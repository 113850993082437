import prawojazdyProBackground from '../images/projects/prawojazdy.pro/prawojazdy.pro-background.png';

export const ProjectsData = {
    Projects: {
        isInteractive: 'true',
        projectsArray:[
            {
                image:prawojazdyProBackground,
                header: "Aplikacja e-learningowa do nauki prawa jazdy",
                link:"/realizacje/prawojazdy.pro",
                linkText:"Więcej informacji",
                type:[
                    0,
                    1,
                    2,
                    3
                ]
            }
        ]
    }
}