import mainImage from '../images/main-images/Services-Two.svg'
import WebCoding from '../images/main-images/WebCoding.svg'
import MobileCoding from '../images/main-images/MobileCoding.svg'
import ProductDesign from '../images/main-images/ProductDesign.svg'

export const ServicesListData = {
    Main:{
        header:'Sprawdź co możemy wam zaoferować',
        desc:'Zajmujemy się kompleksowym tworzeniem systemów informatycznych. Dostarczamy kod spełniający najwyższe standardy bo wiemy jak wielkie znaczenia ma to przy tworzeniu waszych aplikacji biznesowych.',
        img: mainImage,
        reversed: 'false',
        button:{
            link:"/kontakt",
            text:"Skontaktuj się z nami",
        }
    },
    ServicesTitle:{
        header: 'Nasze usługi',
        desc: 'Zapoznaj się z tym, w czym jesteśmy najlepsi'
    },
    ServicesDetails:[
        {
            header:'Aplikacje webowe',
            desc:'Przygotowujemy internetowe aplikacje dedykowane dla małych, średnich i dużych firm doskonalące procesy biznesowe.',
            img: WebCoding,
            backgroundNumber: '01',
            fullview: 'false',
            dataLink:{
                link: '/usługi/aplikacje-webowe',
                linkText: 'Więcej informacji'
            }
        },
        {
            header:'Aplikacje mobilne',
            desc:'Produkujemy natywne aplikacje mobilne bazujące na analizie wymagań klienta oraz spełniające oczekiwania użytkowników.',
            img: MobileCoding,
            backgroundNumber: '02',
            reversed: 'false',
            fullview: 'false',
            dataLink:{
                link: '/usługi/aplikacje-mobilne',
                linkText: 'Więcej informacji'
            }
        },
        {
            header:'Produkt design',
            desc:'Wiemy jakie wielkie znaczenie ma zaprojektowanie estetycznych i intuicyjnych interfejsów użytkownika dlatego przykładamy do tego szczególną uwagę.',
            img: ProductDesign,
            backgroundNumber: '03',
            fullview: 'false',
            dataLink:{
                link: '/usługi/produkt-design',
                linkText: 'Więcej informacji'
            }
        } 
    ]

}